import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function LLPToPvtCompany() {
  /* Slider */
  // const LlpToPvtSlider = [
  //   {
  //     content_color: false,
  //     header_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Conversion of LLP to Private Company`,
  //     content: `Advisory service for Conversion of LLP to Private Company`,
  //     image: "/imgs/registration/shop/shop-est-slider.png",
  //     alt_tag:
  //       "Best Online Conversion of LLP to Private Company Compliance Filing",
  //   },
  //   {
  //     content_color: false,
  //     header_color: false,
  //     marginTop: 70,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Conversion of LLP to Private Company Service`,
  //     content: `Fast And Secure Online Conversion of LLP to Private Company Compliance Service`,
  //     image: "/imgs/registration/shop/shop-img-slide.png",
  //     alt_tag:
  //       "Best Conversion of LLP to Private Company Service near Bangalore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `LLP To Pvt Ltd Conversion`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const LlpToPvtAboutData = {
    header: `Conversion of LLP to Private Company`,
    sub_title: `Best Compliance Service For Conversion of LLP to Private Company.`,
    content_paragraph: [
      `Provisions specified in Company Rules, 2014 and Section 366
        of the Companies Act, 2013. Managing an LLP-type company
        organization might be challenging in comparison to other
        business forms because they provide more opportunities for
        the expansion and development of business operations.
        we convert the LLP into a Private Limited Company
        to take advantage of the exceptional benefits and to draw
        shareholders.`,

      // `There are entrepreneurs in India who begin their business
      //   careers as Limited Liability Partnerships (LLP), and after
      //   seeing positive business growth, they are eager to change
      //   their LLP firm into a private limited company.`,
    ],
  };

  /* Scroll Nav Data */
  const LlpToPvtScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#reasons`,
      heading: `Reasons`,
    },
    {
      id: `#benefitss`,
      heading: `Benefits`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
  ];

  /* Img Content Component Data */
  const LlpToPvtIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Procedure for Changing LLP to a private limited company',
    image: '/imgs/assited/cfo-accounting.png',
    alt_tag: 'Changing LLP to a private limited company',
    Paragraph: `It is a sensible choice to convert an LLP into a
      private limited company, and this must be done in
      accordance with all applicable laws and rules. In
      the case of foreign direct investment, private
      limited corporations are thought to be better to LLP
      (FDI). The transition from an LLP to a PLC
      represents the next phase of the company's
      development.`,
    points: [
      {
        content: `Getting the name approved by the ROC (Registrar of
            Companies) by submitting an online application is
            the first step in the conversion procedure.`,
        icon: true,
      },
      {
        content: `Acquiring DSC and DIN for each of the company's
          seven directors. The MCA portal allows for the
          issuance of DIN.`,
        icon: true,
      },
      {
        content: `The applicant must create and submit form No. URC-1
          after receiving the Registrar of Companies'
          permission for the name.`,
        icon: true,
      },
      {
        content: `The formal Memorandum of Association (MOA) and
          Articles of Association (AOA) for the company must
          then be prepared and submitted to the Registrar of
          Companies.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const LlpToPvtCfData = {
    id: 'reasons',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Reasons for LLP and Private Limited Company Registration',
    content: ``,
    body: [
      {
        head: 'Reasons for Pvt Ltd registration',
        points: `There are no shareholders of LLP. An LLP's owners are
          all regarded as Partners of the company and are not
          interested in managing the business since it is not a
          good investment for many types of investors, including
          private equity investors and venture capitalists.`,
        icon: true,
      },
      {
        points: `Private Limited Company is viewed by the investor as
          the finest choice. Therefore, it is preferable to
          transform the company into a private limited company
          if the LLP is expanding.`,
        icon: true,
      },
      {
        head: 'Reasons for LLP Registration',
        points: `Assisting small enterprises with LLP conception.`,
        icon: true,
      },
      {
        points: `Easy to start, run, and control offers the advantages
          of limited liability and also helps in internal
          corporate organization.`,
        icon: true,
      },
      {
        points: `If the yearly turnover is under Rs. 40 lakhs and the
          capital contribution is under Rs. 25 lakhs, there is
          no need for an audit.`,
        icon: true,
      },
      {
        points: `No tax on dividend distributions is required (DDT).`,
        icon: true,
      },
      {
        points: `There is no requirement to hold a board meeting or
          yearly business gathering.`,
        icon: true,
      },
      {
        points: `When compared to registering a Private Limited
          Company, the LLP registration process is
          straightforward and easy.`,
        icon: true,
      },
      {
        head: 'Conversion of an LLP to a Private Company Checklist',
        points: `To change an LLP from an LLP to a PLC, you must meet a number of
          requirements. Additionally, a newspaper advertisement
          regarding the conversion must be printed in both local
          and national publications.`,
        icon: true,
      },
      {
        head: 'LLP vs. Private Limited Company',
        points: `LLPs are one of the most popular company structures,
        particularly for small companies with annual sales
        turnovers of under Rs. 40 lakhs and capital
        contributions of no more than Rs. 25 lakhs. While
        private limited companies are required to undertake
        an audit each year, LLPs with these conditions are
        exempt from this requirement.`,
        icon: true,
      },
      {
        points: `It is necessary to conduct an audit if an LLP's
        annual revenue exceeds Rs. 40 lakhs or if it has
        contributed more than Rs. 25 lakhs in capital, which
        will make it almost identical to a private limited
        company. This is one of the reasons why the LLP's
        owners want to convert their LLP to a private
        limited company.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const LlpToPvtImages = [
    '/imgs/registration/iso/iso-img.png',
    '/imgs/business/llp.png',
    '/imgs/business/compreg.png',
  ];

  /*  Slider Content Component Data */
  const LlpToPvtSCData = {
    id: 'benefitss',
    background: [],
    mt_div: 'mt-3',
    header: `Benefits of changing from an LLP to a private
      limited company`,
    content: [
      {
        points: `Little liability.`,
        icon: true,
      },
      {
        points: `Distinct legal status.`,
        icon: true,
      },
      {
        points: `Simple money raising.`,
        icon: true,
      },
      {
        points: `Depreciation and losses.`,
        icon: true,
      },
      {
        points: `Maintenance of brand value.`,
        icon: true,
      },

      {
        points: `Plan for employee stock ownership.`,
        icon: true,
      },
    ],
    images: LlpToPvtImages,
  };
  /*  Content Img Component Data */
  const LlpToPvtCiData = {
    id: 'documents',
    background: 'main-content',
    mt_div: 'mt-3',
    header: `Documents required for LLP to private limited
      company conversion`,
    paragraph: ``,
    points: [
      {
        content: `Address validation for owner.`,
        icon: true,
      },
      {
        content: `Identity documentation of owner.`,
        icon: true,
      },
      {
        content: `Passport-size image of the owner.`,
        icon: true,
      },
      {
        head: `Documents required at the time of filing of Form
          URC-1`,
        content: `Along with the membership list, members' names,
          addresses, and the number of shares they possess are
          included.`,
        icon: true,
      },
      {
        content: `Give all the information, including each director's
          name, address, passport number, DIN, and expiration
          date.`,
        icon: true,
      },
      {
        content: `Copy of the Limited Liability Partnership agreement, a list of
          the LLP's partners, and an authenticated certified
          copy of the registration are also needed.`,
        icon: true,
      },
      {
        content: `It is necessary to obtain a NOC and RoC, from each investor or creditor 
        and the account statement for the company.`,
        icon: true,
      },
    ],
    image: '/imgs/assited/customized-consulting.png',
    alt_tag: `Documents required for LLP to private limited
      company conversion`,
  };

  var FAQ_data = [
    {
      header:
        'What benefit does changing an LLP to a Private Limited Company provide?',
      body: [
        {
          content: `Distinct legal status Maintenance of Brand Value Simple
          Fundraising Unabsorbed depreciation and losses carried forward
          Employee Stock Ownership Program with Limited Owner Liability.`,
          icon: false,
        },
      ],
    },

    {
      header: 'The SPICe form can be used to file how many DIN?',
      body: [
        {
          content: `The SPICe form can be used to apply three DIN.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Are LLPs capable of becoming Private Limited Companies?',
      body: [
        {
          content: `An LLP may be transformed into a Private Limited Company in
          accordance with the provisions outlined in Section 366 of the
          Companies Act, 2013, and the Company (Authorized to Register)
          Rules, 2014.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can LLP borrow money from a bank?',
      body: [
        {
          content: `Debt financing, such as a term loan or bank overdraft, is possible
          in the event of LLP.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is Private Limited Company superior than LLP?',
      body: [
        {
          content: `Tax advantages are believed because they are registered with the
          Ministry of Corporate Affairs and can be applied to an unlimited
          number of owners or partners. In addition to being less expensive
          to start and manage than a private limited company, it also
          carries less compliance."`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' How to file conversion form in case of more than seven partners in the LLP? ',
      body: [
        {
          content: `In case of more than 7 partners in the LLP at the time of conversion into Company 
          then Company have to file Scan copy of physically prepared MOA & AOA."`,
          icon: true,
        },
        {
          content: `In above mentioned situation company have to file 1. URC-1 and 2. INC-32. No need 
           INC-33 and INC 34 in the above mentioned situations."`,
          icon: true,
        },
      ],
    },
    {
      header: ' What is the minimum capital required for LLP? ',
      body: [
        {
          content: `There is no minimum capital requirement in LLP. An LLP can be formed with the least possible capital.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' How can a partnership be converted into a private limited company? ',
      body: [
        {
          content: `File an affidavit, duly notarised, from all the partners to provide that in the event of 
          registration, necessary documents or papers shall be submitted to authority with which the firm was 
          earlier registered, for its dissolution as partnership firm consequent to its conversion into private 
          limited company.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Is LLP better than Private Limited Company? ',
      body: [
        {
          content: `It offers limited liability, offers tax advantages, can accommodate an unlimited number 
          of partners, and is credible in that it is registered with the Ministry of Corporate Affairs (MCA). 
          At the same time, it has less compliance than a private limited company and is also significantly 
          cheaper to start and maintain.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Conversion of LLP to Private Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={LlpToPvtSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={LlpToPvtAboutData} />

          <Counter />

          <ScrollNav scroll_data={LlpToPvtScrollId} />

          <ImgContent item={LlpToPvtIcData} />

          <ContentForm CFSection_data={LlpToPvtCfData} />

          <SliderContent ScSection_data={LlpToPvtSCData} />

          <ContentImg CISection_data={LlpToPvtCiData} />

          <Cta />
          <Guidance />
          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
